
/**
 * This file is a wrapper around the Lighthouse widget. It initializes the widget and provides a way to show/hide the
 * widget, navigate to articles and set suggestions.
 * Also provides a way to listen to the state of the widget (shown/hidden).
 */

// The Lighthouse widget is loaded asynchronously and we need to keep track of when it is ready.
let ready = false;

// State of the Lighthouse widget.
let shown = false;

type LighthouseModalShownListener = (isShown: boolean) => void

const listeners: LighthouseModalShownListener[] = [];

const notifyListeners = () => {
    listeners.forEach(listener => listener(shown));
}

/**
 * Event detail for incoming helpDocs events.
 */
type EventDetail = {
    articleId?: string;
    show?: boolean;
    suggestions?: string[];
}

/**
 * Assure that the Lighthouse widget is ready and call the callback with the Lighthouse object.
 */
const withLighthouse = (callback: (Lighthouse: any, hdlh: any) => void) => {
    if (!ready) {
        setTimeout(() => withLighthouse(callback), 100);
        return;
    }
    const { Lighthouse, hdlh } = window as any;
    callback(Lighthouse, hdlh);
}

/**
 * Initialize the Lighthouse widget. This function should be called when the application is loaded.
 */
export const initialize = () => {
    if ((window as any).hdlh) {
        // Lighthouse already initialized
        return;
    }
    
    window.addEventListener('helpDocs', (event) => {
        // Handle incoming events
        const eventDetail = (event as any).detail as EventDetail;
        withLighthouse((Lighthouse, hdhl) => {
            // Navigate to an article
            if (eventDetail.articleId) {
                navigateToArticle(Lighthouse, eventDetail.articleId);
            }
            // Show or hide the widget
            if (eventDetail.show != null) {
                toggleShow(Lighthouse, eventDetail.show);
            }
            // Set suggestions
            if (eventDetail.suggestions) {
                setSuggestions(Lighthouse, hdhl, eventDetail.suggestions);
            }
        });
    });
    (window as any).hdlh = {
        // ...this.options,
        shared_password: '00d2b0944e18d8646c8e3906f59aa72e',
        widget_key: '6mrloqd1zhvpw3br92fv',
        suggestions: [],
        color_mode: 'light',
        onReady() {
            ready = true;
        },
        onShow() {
            shown = true;
            notifyListeners();
        },
        onHide() {
            shown = false;
            notifyListeners();
        },
        onNavigate() {
            shown = true;
            notifyListeners();
        },
      };
      (function(h,d){var s=d.createElement("script");s.type='text/javascript';s.async=true;s.src=h+"?t="+new Date().getTime();d.head.appendChild(s);})("https://lighthouse.helpdocs.io/load",document);
}

/**
 * Register a listener that will be called when the Lighthouse widget is shown or hidden.
 */
export const isShown = (listener: LighthouseModalShownListener): void => {
    if (listeners.indexOf(listener) < 0) {
        listeners.push(listener);
        listener(shown);
    }
}

/**
 * Show or hide the Lighthouse widget.
 */
const toggleShow = (Lighthouse: any, show: boolean) => {
    if (show && !shown) {
        Lighthouse.show();
    } else if (!show && shown) {
        Lighthouse.hide();
    }
}

/**
 * Set the suggestions that will be shown in the Lighthouse widget.
 */
const setSuggestions = (Lighthouse: any, hdlh: any, suggestions: string[]) => {
    hdlh.suggestions = suggestions;
    if (shown) {
        // Need to hide and show again to update the suggestions
        Lighthouse.hide();
        Lighthouse.show();
    }
}

/**
 * Navigate to an article in the Lighthouse widget.
 */
const navigateToArticle = (Lighthouse: any, articleId: string) => {
    Lighthouse.navigate('article', articleId);
}
